import { Options } from '@/types/general';

type Elements = {
  module: HTMLDivElement | null;
  selects: HTMLSelectElement[];
  season: HTMLSelectElement | null;
  month: HTMLSelectElement | null;
  type: HTMLSelectElement | null;
  instrument: HTMLSelectElement | null;
  toggle: HTMLButtonElement | null;
};

type SelectedOptions = {
  season: string | undefined;
  month: string | undefined;
  type: string | undefined;
  instrument: string | undefined;
  view: string | undefined;
};

type EventsList = {
  elements: Elements;
  initialMonthValue: string;
  urlMonthValue: string | null; // Store the month from URL
  isInitialLoad: boolean; // Flag to track initial page load
  init: () => void;
  bindEvents: () => void;
  updateEvents: (list: string) => void;
  updateCalendar: (calendar: string) => void;
  updateOptions: (select: HTMLSelectElement, options: Options) => void;
  getSelectedOptions: () => SelectedOptions;
  adjustSelectWidth: (selectElement: HTMLSelectElement) => void;
  fetchEvents: (select: HTMLSelectElement, overrideOptions?: Partial<SelectedOptions>) => Promise<void>;
  updateURLParameters: (options: SelectedOptions) => void;
  setInitialStateFromURL: () => void;
  hasOptionWithValue: (selectElement: HTMLSelectElement, value: string) => boolean;
};

const eventsList: EventsList = {
  elements: {
    module: null,
    selects: [],
    season: null,
    month: null,
    type: null,
    instrument: null,
    toggle: null,
  },

  initialMonthValue: '',
  urlMonthValue: null,
  isInitialLoad: true,

  init() {
    this.elements.module = document.querySelector('.module--events-list');

    if (this.elements.module?.dataset.noFilters === 'true') {
      return;
    }

    this.elements.selects = Array.from(document.querySelectorAll<HTMLSelectElement>('.module--events-list select'));
    this.elements.season = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-season"]');
    this.elements.month = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-month"]');
    this.elements.type = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-type"]');
    this.elements.instrument = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-instrument"]');
    this.elements.toggle = document.querySelector<HTMLButtonElement>('.module--events-list button[data-toggle="view"]');

    if(!this.elements.module) return;

    // Capture URL parameters before any processing
    const searchParams = new URLSearchParams(window.location.search);
    this.urlMonthValue = searchParams.get('month');

    // Set initial UI state from URL params
    this.setInitialStateFromURL();

    // Store initial month value after URL params are applied
    if (this.elements.month) {
      this.initialMonthValue = this.elements.month.value;
    }

    this.bindEvents();

    // Initial fetch with URL parameters
    // If we have URL parameters, use them directly for the initial fetch
    // This ensures the backend gets the exact URL parameters
    if (searchParams.toString()) {
      const urlOptions: Partial<SelectedOptions> = {};

      // Extract all relevant parameters from URL
      if (searchParams.get('season')) urlOptions.season = searchParams.get('season') || undefined;
      if (searchParams.get('month')) urlOptions.month = searchParams.get('month') || undefined;
      if (searchParams.get('type')) urlOptions.type = searchParams.get('type') || undefined;
      if (searchParams.get('instrument')) urlOptions.instrument = searchParams.get('instrument') || undefined;
      if (searchParams.get('view')) urlOptions.view = searchParams.get('view') || undefined;

      // Use the season select as the trigger but send the exact URL params
      if (this.elements.season) {
        this.fetchEvents(this.elements.season, urlOptions);
      }
    } else {
      // No URL parameters, just do a regular fetch
      if (this.elements.season) {
        this.fetchEvents(this.elements.season);
      }
    }
  },

  bindEvents() {
    window.addEventListener('load', () => {
      if (this.elements.selects.length) {
        this.elements.selects.forEach((select) => {
          if (select.selectedIndex !== -1) {
            this.adjustSelectWidth(select);
          }

          select.addEventListener('change', async () => {
            // If season is changed, override month to 'all' in the backend request
            if (select === this.elements.season && !this.isInitialLoad) {
              await this.fetchEvents(select, { month: 'all' });
            } else {
              // Update initial month value if month is manually changed
              if (select === this.elements.month) {
                this.initialMonthValue = select.value;
              }

              await this.fetchEvents(select);
            }

            this.adjustSelectWidth(select);
          });
        });
      }

      if (this.elements.toggle) {
        this.elements.toggle.addEventListener('click', async () => {
          if (!this.elements.module || !this.elements.toggle || !this.elements.month) return;

          const currentView = this.elements.module.dataset.view;
          const newView = currentView === 'list' ? 'calendar' : 'list';

          if (newView === 'calendar') {
            // Store current month value before switching to calendar
            const currentMonthValue = this.elements.month.value;

            // When switching to calendar, set to current month if no explicit selection
            if (currentMonthValue === '' || currentMonthValue === 'upcoming' || currentMonthValue === 'all') {
              const today = new Date();
              const year = today.getFullYear();
              const month = (today.getMonth() + 1).toString().padStart(2, '0');
              const currentMonthDate = `${year}${month}01`;

              const options = Array.from(this.elements.month.options);
              const currentMonthOption = options.find(option =>
                option.value.startsWith(currentMonthDate) &&
                option.value !== 'all' &&
                option.value !== 'upcoming'
              );

              if (currentMonthOption) {
                this.elements.month.value = currentMonthOption.value;
              } else {
                // Find next available month
                const futureOption = options.find(option =>
                  option.value > currentMonthDate &&
                  option.value !== 'all' &&
                  option.value !== 'upcoming'
                );
                if (futureOption) {
                  this.elements.month.value = futureOption.value;
                }
              }
            }
          } else {
            // When switching back to list, return to initial state unless there was
            // an explicit month selection before any view switching
            if (this.initialMonthValue === '' ||
                this.initialMonthValue === 'upcoming' ||
                this.initialMonthValue === 'all') {
              this.elements.month.value = 'upcoming';
            } else {
              // Keep the explicitly selected month
              this.elements.month.value = this.initialMonthValue;
            }
          }

          // Update the view
          this.elements.module.dataset.view = newView;
          this.elements.toggle.textContent = newView === 'list' ? 'Calendar View' : 'List View';

          // Fetch events with the new state
          await this.fetchEvents(this.elements.month);

          // Update URL parameters after the view change
          this.updateURLParameters(this.getSelectedOptions());
        });
      }
    });

    window.addEventListener('popstate', () => {
      this.isInitialLoad = true; // Reset initial load flag for browser navigation
      this.setInitialStateFromURL();

      // Extract parameters from the new URL
      const searchParams = new URLSearchParams(window.location.search);
      const urlOptions: Partial<SelectedOptions> = {};

      if (searchParams.get('season')) urlOptions.season = searchParams.get('season') || undefined;
      if (searchParams.get('month')) urlOptions.month = searchParams.get('month') || undefined;
      if (searchParams.get('type')) urlOptions.type = searchParams.get('type') || undefined;
      if (searchParams.get('instrument')) urlOptions.instrument = searchParams.get('instrument') || undefined;
      if (searchParams.get('view')) urlOptions.view = searchParams.get('view') || undefined;

      // Fetch with the exact URL parameters
      if (this.elements.season) {
        this.fetchEvents(this.elements.season, urlOptions);
      }
    });
  },

  updateEvents(list: string) {
    const content = this.elements.module?.querySelector<HTMLUListElement>('.module__content--list ul');
    if (!content) return;
    content.innerHTML = list;
  },

  updateCalendar(calendar: string) {
    const content = this.elements.module?.querySelector<HTMLDivElement>('.module__content--calendar');
    if (!content) return;
    content.innerHTML = calendar;
  },

  updateOptions(select: HTMLSelectElement, options: Options) {
    if (!this.elements.month || !this.elements.type || !this.elements.instrument) return;

    // Store current values before updating options to preserve selections
    const currentMonthValue = this.elements.month.value;
    const currentTypeValue = this.elements.type.value;
    const currentInstrumentValue = this.elements.instrument.value;

    switch (select.dataset.filter) {
      case 'event-season':
        this.elements.month.innerHTML = options.months.map((month) => `<option value="${month.value}">${month.label}</option>`).join('');

        // For season changes, handle month selection differently based on context
        if (this.isInitialLoad && this.urlMonthValue && this.hasOptionWithValue(this.elements.month, this.urlMonthValue)) {
          // If this is initial load and we have a URL month value, use it
          this.elements.month.value = this.urlMonthValue;
          this.urlMonthValue = null; // Clear after use
        } else if (!this.isInitialLoad) {
          // If user changed the season manually, default to 'all'
          // Find and select the 'all' option
          const allOption = Array.from(this.elements.month.options).find(option => option.value === 'all');
          if (allOption) {
            this.elements.month.value = 'all';
          }
        } else if (currentMonthValue && this.hasOptionWithValue(this.elements.month, currentMonthValue)) {
          // Otherwise, try to keep the current value if available
          this.elements.month.value = currentMonthValue;
        }

        if (this.elements.month.selectedIndex !== -1) {
          this.adjustSelectWidth(this.elements.month);
        }

        this.elements.type.innerHTML = options.types.map((type) => {
          let output = `<option value="${type.value}">${type.label}</option>`;
          if (type.children) {
            output += type.children.map((child) => `<option value="${child.value}">- ${child.label}</option>`).join('');
          }
          return output;
        }).join('');

        // Try to restore the selected type
        if (currentTypeValue && this.hasOptionWithValue(this.elements.type, currentTypeValue)) {
          this.elements.type.value = currentTypeValue;
        }

        if (this.elements.type.selectedIndex !== -1) {
          this.adjustSelectWidth(this.elements.type);
        }

        this.elements.instrument.innerHTML = options.instruments.map((instrument) => `<option value="${instrument.value}">${instrument.label}</option>`).join('');

        // Try to restore the selected instrument
        if (currentInstrumentValue && this.hasOptionWithValue(this.elements.instrument, currentInstrumentValue)) {
          this.elements.instrument.value = currentInstrumentValue;
        }

        if (this.elements.instrument.selectedIndex !== -1) {
          this.adjustSelectWidth(this.elements.instrument);
        }
        break;

      case 'event-month':
        this.elements.type.innerHTML = options.types.map((type) => {
          let output = `<option value="${type.value}">${type.label}</option>`;
          if (type.children) {
            output += type.children.map((child) => `<option value="${child.value}">- ${child.label}</option>`).join('');
          }
          return output;
        }).join('');

        // Try to restore the selected type
        if (currentTypeValue && this.hasOptionWithValue(this.elements.type, currentTypeValue)) {
          this.elements.type.value = currentTypeValue;
        }

        if (this.elements.type.selectedIndex !== -1) {
          this.adjustSelectWidth(this.elements.type);
        }

        this.elements.instrument.innerHTML = options.instruments.map((instrument) => `<option value="${instrument.value}">${instrument.label}</option>`).join('');

        // Try to restore the selected instrument
        if (currentInstrumentValue && this.hasOptionWithValue(this.elements.instrument, currentInstrumentValue)) {
          this.elements.instrument.value = currentInstrumentValue;
        }

        if (this.elements.instrument.selectedIndex !== -1) {
          this.adjustSelectWidth(this.elements.instrument);
        }
        break;

      case 'event-type':
        this.elements.instrument.innerHTML = options.instruments.map((instrument) => `<option value="${instrument.value}">${instrument.label}</option>`).join('');

        // Try to restore the selected instrument
        if (currentInstrumentValue && this.hasOptionWithValue(this.elements.instrument, currentInstrumentValue)) {
          this.elements.instrument.value = currentInstrumentValue;
        }

        if (this.elements.instrument.selectedIndex !== -1) {
          this.adjustSelectWidth(this.elements.instrument);
        }
        break;
    }
  },

  // Helper function to check if a select element has an option with a specific value
  hasOptionWithValue(selectElement: HTMLSelectElement, value: string): boolean {
    return Array.from(selectElement.options).some(option => option.value === value);
  },

  getSelectedOptions(): SelectedOptions {
    return {
      season: this.elements.season?.value,
      month: this.elements.month?.value,
      type: this.elements.type?.value,
      instrument: this.elements.instrument?.value,
      view: this.elements.module?.dataset.view
    };
  },

  adjustSelectWidth(selectElement: HTMLSelectElement) {
    if (!this.elements.module) return;

    // Safety check: ensure there's a selected option
    if (selectElement.selectedIndex === -1) {
      // If no option is selected, just use a default width
      selectElement.style.width = '100px';
      return;
    }

    const tmpSelect = document.createElement('select');
    const tmpOption = document.createElement('option');

    // Get the selected option text
    const selectedText = selectElement.options[selectElement.selectedIndex].text;
    tmpOption.textContent = selectedText;
    tmpSelect.appendChild(tmpOption);

    tmpSelect.style.visibility = 'hidden';
    tmpSelect.style.position = 'absolute';
    tmpSelect.style.width = 'auto';

    this.elements.module.appendChild(tmpSelect);
    const width = tmpSelect.clientWidth;
    this.elements.module.removeChild(tmpSelect);

    // Set a minimum width of 50px to ensure it's always visible
    selectElement.style.width = `${Math.max(width, 50)}px`;
  },

  async fetchEvents(select: HTMLSelectElement, overrideOptions?: Partial<SelectedOptions>) {
    this.elements.module?.classList.add('loading');

    try {
      // Get current selections
      const selectedOptions = this.getSelectedOptions();

      // Apply any override options
      if (overrideOptions) {
        Object.assign(selectedOptions, overrideOptions);
      }

      // Debug log for initial load
      if (this.isInitialLoad) {
        console.log('Initial fetch with options:', selectedOptions);
      }

      const response = await window.jQuery.ajax({
        method: 'POST',
        url: window.curtis.ajaxurl,
        data: {
          action: 'action_get_events',
          selected: selectedOptions
        },
      });

      const data = JSON.parse(response) as { options: Options; results: string; calendar: string };
      this.updateOptions(select, data.options);
      this.updateEvents(data.results);
      this.updateCalendar(data.calendar);

      // Capture the current selections after options have been updated
      const currentSelections = this.getSelectedOptions();
      this.updateURLParameters(currentSelections);

      // Update the initialMonthValue if it changed
      if (this.elements.month && this.elements.month.value) {
        this.initialMonthValue = this.elements.month.value;
      }

      // After first successful fetch, no longer in initial load state
      this.isInitialLoad = false;

    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      this.elements.module?.classList.remove('loading');
    }
  },

  updateURLParameters(options: SelectedOptions) {
    const searchParams = new URLSearchParams();
    Object.entries(options).forEach(([ key, value ]) => {
      if (value) {
        searchParams.set(key, value);
      }
    });

    const newURL = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState(null, '', newURL);

    if (this.elements.selects.length) {
      this.elements.selects.forEach(select => {
        // Only adjust if the select has options and one is selected
        if (select.options.length > 0 && select.selectedIndex !== -1) {
          this.adjustSelectWidth(select);
        }
      });
    }
  },

  setInitialStateFromURL() {
    const searchParams = new URLSearchParams(window.location.search);

    const season = searchParams.get('season');
    if (season && this.elements.season) {
      this.elements.season.value = season;
    }

    const view = searchParams.get('view') || 'list';
    const month = searchParams.get('month');

    // Remember the month from URL for later use if it's not already set
    if (month && !this.urlMonthValue) {
      this.urlMonthValue = month;
    }

    if (this.elements.month) {
      if (!month) {
        // Set default based on view
        if (view === 'list') {
          this.elements.month.value = 'upcoming';
        } else {
          // For calendar, find current month option
          const today = new Date();
          const year = today.getFullYear();
          const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');
          const currentMonthDate = `${year}${currentMonth}01`;

          const options = Array.from(this.elements.month.options);
          const currentMonthOption = options.find(option =>
            option.value.startsWith(currentMonthDate) &&
            option.value !== 'all' &&
            option.value !== 'upcoming'
          );

          if (currentMonthOption) {
            this.elements.month.value = currentMonthOption.value;
          } else {
            // Find next available month
            const futureOption = options.find(option =>
              option.value > currentMonthDate &&
              option.value !== 'all' &&
              option.value !== 'upcoming'
            );
            if (futureOption) {
              this.elements.month.value = futureOption.value;
            } else {
              // If no matching month found, default to 'all'
              this.elements.month.value = 'all';
            }
          }
        }
      } else {
        // If the URL has a month parameter and it's available in options, set it
        if (Array.from(this.elements.month.options).some(option => option.value === month)) {
          this.elements.month.value = month;
        }
        // Note: if it's not in options, we don't set it directly, but we've stored it
        // in urlMonthValue to use after the AJAX call populates the options
      }
    }

    const type = searchParams.get('type');
    if (type && this.elements.type) {
      this.elements.type.value = type;
    }

    const instrument = searchParams.get('instrument');
    if (instrument && this.elements.instrument) {
      this.elements.instrument.value = instrument;
    }

    if (this.elements.module) {
      this.elements.module.dataset.view = view;
      if (this.elements.toggle) {
        this.elements.toggle.textContent = view === 'list' ? 'Calendar View' : 'List View';
      }
    }

    if (this.elements.selects.length) {
      this.elements.selects.forEach(select => {
        // Only adjust width if the select has a selected option
        if (select.selectedIndex !== -1) {
          this.adjustSelectWidth(select);
        }
      });
    }
  },
};

export default eventsList;
