import 'slick-carousel';

type Events = {
  elements: {
    modules: HTMLElement[];
  };
  init: () => void;
  bindEvents: () => void;
  createSlider: (module: HTMLElement) => void;
  handleResize: () => void;
  equalizeHeights: (slider: JQuery<HTMLElement>) => void;
  updateNavigation: (module: HTMLElement, currentSlide: number) => void;
};

const events: Events = {
  elements: {
    modules: Array.from(document.querySelectorAll<HTMLElement>('.module--events'))
  },

  init() {
    this.bindEvents();
    window.addEventListener('resize', this.handleResize.bind(this));
  },

  bindEvents() {
    if (this.elements.modules.length > 0) {
      this.elements.modules.forEach(module => {
        this.createSlider(module);
      });
    }
  },

  createSlider(module: HTMLElement) {
    const slider = module.querySelector<HTMLElement>('.module__slider');
    const desktopNav = module.querySelector<HTMLElement>('.module__slider--nav.desktop');
    const mobileNav = module.querySelector<HTMLElement>('.module__slider--nav.mobile');

    if (!slider || !desktopNav || !mobileNav) return;

    const $slick = window.jQuery(slider);

    $slick.on('init', (_event: JQuery.Event, slick: JQuerySlick) => {
      const totalSlides = slick.slideCount || 0;
      const desktopPageCount = Math.ceil(totalSlides / 2);

      for (let i = 0; i < desktopPageCount; i++) {
        desktopNav.insertAdjacentHTML('beforeend', `<button type="button" class="slide-link" aria-label="Go to page ${i + 1}"><svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M14.9276 0.670298C6.97533 2.70607 0.5 9.55889 0.5 15.8268C0.5 22.0947 6.97533 25.4437 14.9276 23.4021C22.8741 21.2173 29.5 14.3645 29.5 8.1023C29.5 1.84015 22.8741 -1.52031 14.9276 0.670298Z" fill="black"></path></svg></button>`);
      }

      for (let i = 0; i < totalSlides; i++) {
        mobileNav.insertAdjacentHTML('beforeend', `<button type="button" class="slide-link" aria-label="Go to slide ${i + 1}"><svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M14.9276 0.670298C6.97533 2.70607 0.5 9.55889 0.5 15.8268C0.5 22.0947 6.97533 25.4437 14.9276 23.4021C22.8741 21.2173 29.5 14.3645 29.5 8.1023C29.5 1.84015 22.8741 -1.52031 14.9276 0.670298Z" fill="black"></path></svg></button>`);
      }

      this.updateNavigation(module, 0);
      setTimeout(() => this.equalizeHeights($slick), 100);
    });

    $slick.slick({
      slidesToShow: 2,
      slidesToScroll: 2,
      adaptiveHeight: false,
      slide: '.module__slide',
      rows: 0,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 6000,
      infinite: true,
      responsive: [
        {
          breakpoint: 999,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '64px',
            centerMode: true,
            autoplay: false,
          }
        }
      ]
    });

    $slick.on('beforeChange', (_e: JQuery.Event, _slick: JQuerySlick, _currentSlide: number, nextSlide: number) => {
      this.updateNavigation(module, nextSlide);
    });

    window.jQuery('.module__slider--nav').on('click', '.slide-link', function(this: HTMLElement) {
      const isMobile = window.innerWidth < 999;
      const $navSet = isMobile ? window.jQuery(mobileNav) : window.jQuery(desktopNav);
      const dotIndex = Array.from($navSet[0].children).indexOf(this);
      const slideIndex = isMobile ? dotIndex : dotIndex * 2;
      if ($slick.hasClass('slick-initialized')) {
        $slick.slick('slickGoTo', slideIndex);
      }
    });

    $slick.on('afterChange', () => this.equalizeHeights($slick));
  },

  handleResize() {
    this.elements.modules.forEach(module => {
      const slider = module.querySelector<HTMLElement>('.module__slider');
      if (slider) {
        const $slick = window.jQuery(slider);
        if ($slick.hasClass('slick-initialized')) {
          $slick.slick('setPosition');
          this.equalizeHeights($slick);
          this.updateNavigation(module, $slick.slick('slickCurrentSlide'));
        }
      }
    });
  },

  equalizeHeights($slider: JQuery<HTMLElement>) {
    if (!$slider.hasClass('slick-initialized')) return;

    $slider.find('.module__slide, .module__slide > ul, .module__slide > ul > li').height('auto');

    let maxLiHeight = 0;
    $slider.find('.module__slide > ul > li').each(function() {
      maxLiHeight = Math.max(maxLiHeight, window.jQuery(this).outerHeight());
    });

    const slideHeight = maxLiHeight + 2;
    $slider.find('.module__slide, .module__slide > ul, .module__slide > ul > li').height(slideHeight);

    if ($slider.hasClass('slick-initialized')) {
      $slider.slick('setPosition');
    }
  },

  updateNavigation(module: HTMLElement, currentSlide: number) {
    const desktopNav = module.querySelector<HTMLElement>('.module__slider--nav.desktop');
    const mobileNav = module.querySelector<HTMLElement>('.module__slider--nav.mobile');
    if (!desktopNav || !mobileNav) return;

    const isMobile = window.innerWidth < 999;

    if (isMobile) {
      mobileNav.querySelectorAll('.slide-link').forEach((link, index) => {
        link.classList.toggle('active', index === currentSlide);
      });
    } else {
      desktopNav.querySelectorAll('.slide-link').forEach((link, index) => {
        link.classList.toggle('active', index === Math.floor(currentSlide / 2));
      });
    }
  }
};

export default events;
